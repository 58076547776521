import React, { useEffect } from 'react'
import { graphql } from 'gatsby'

import BrandLayout from 'src/components/layouts/brandLayout'
import SportLayout from 'src/components/layouts/sportLayout'
import BusinessLayout from 'src/components/layouts/businessLayout'

import Seo from 'src/components/seoWp'
import Default from 'src/components/sharedPages/default'
import BlogList from 'src/components/sharedPages/blogList'

import 'src/styles/gutenbergStyles.scss'
import 'src/styles/brand.scss'


const BlogPageTemplate = ({ data: { page, subpage }, pageContext, location }) => {

  /*      CHECK 404 SEARCH PARAM      */
  const is404search = location.search !== '' ? location.search.substring(1) : false

  /*      CHECK LOCATION.STATE.PREVPATH     */
  const statePrevPath = (typeof location.state !== 'undefined' && location.state !== null) && (typeof location.state.prevPath !== 'undefined' && location.state.prevPath !== null) ? { site: location.state.prevPath } : { site: false }

  /*      CHECK localStorage GETITEM      */
  const localStorageLoco = (typeof localStorage !== 'undefined' && localStorage !== null) ? localStorage.getItem("loco") : null


  /*      UPDATEING    */
  const locationHold = () => {

    if(statePrevPath.site !== false) {
      window.localStorage.setItem("loco", statePrevPath.site)
    }

    if((localStorageLoco === null) && (statePrevPath.site !== false)) {
      window.localStorage.setItem("loco", statePrevPath.site)
    }

    if((localStorageLoco !== null) && (statePrevPath.site === false)) {
      statePrevPath.site = localStorageLoco
    }

    if((localStorageLoco !== null) && (statePrevPath.site !== false)) {
      window.localStorage.setItem("loco", statePrevPath.site)
    }

  }
  useEffect(() => {
    locationHold()
  }, [])  // eslint-disable-line react-hooks/exhaustive-deps

  /*****      USE: is404search || .site || localStorageLoco     *****/
  const prevPath =   
    (is404search === false) ? 
      (statePrevPath.site === false) ?      
        (localStorageLoco === null) ?
          'brand'
        : localStorageLoco
      : statePrevPath.site
    : is404search


  pageContext.siteSlug = prevPath
  pageContext.sitePath = `/${prevPath}/`


  if(pageContext.sitePath === 'business') {
    require('src/styles/business.scss') 
  } else if (pageContext.sitePath === 'sport') {
    require('src/styles/sport.scss') 
  } else {
    require('src/styles/brand.scss')   
  }


  const Layouts = {
    brand: BrandLayout,
    sport: SportLayout,
    business: BusinessLayout
  }
  const Layout = prevPath in Layouts ? Layouts[prevPath] : BrandLayout

  const contentMap = {
    default: Default,
    'blog-list': BlogList
  }
  const Content = page.AcfGatsbyId.pageid in contentMap ? contentMap[page.AcfGatsbyId.pageid] : Default

  // console.log(page)
  // console.log(pageContext.subpage)
  // console.log(subpage.slug)

  return (
    <>
      <Layout pageContext={pageContext} page={page} >
        <Seo 
          seo={(typeof subpage !== 'undefined' && subpage !== null) ? subpage.seo : page.seo} 
          site={prevPath} 
          // slug={page.slug} 
          slug={(typeof subpage !== 'undefined' && subpage !== null) ? subpage.slug : page.slug} 
          gatsbyId={page.AcfGatsbyId.pageid} 
          subpageid={typeof pageContext.subpage !== 'undefined' && pageContext.subpage.AcfGatsbyId.pageid} 
        />
        <Content pageContext={pageContext} page={page} subpage={subpage} />
      </Layout>
    </>
  )
}
export default BlogPageTemplate

export const pageQuery = graphql`
  query BlogPageById($id: String!, $subpageId: String!) {
    page: wpCptBlogPage(id: { eq: $id }) {
      slug
      id
      title
      content
      AcfGatsbyId {
        pageid
      }
      seo {
        focuskw
        title
        canonical
        opengraphUrl
        metaDesc
        schema {
          raw
        }
      }
      featuredImage {
        node {
          altText
          description
          title
          localFile {
            childImageSharp {
              fluid (maxWidth: 1000, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
      wpChildren {
        nodes {
          ... on WpCPTBlogPage {
            id
            slug
            title
            content
            date
            author {
              node {
                name
              }
            }
            cPTTXBlogs {
              nodes {
                name
                slug            
              }
            }
            featuredImage {
              node {
                localFile {
                  childImageSharp {
                    fluid(maxWidth: 1000, quality: 50) {
                      ...GatsbyImageSharpFluid_withWebp
                    }
                  }
                }
              }
            }

          }
        }
      }
    }
    subpage: wpCptBlogPage(id: { eq: $subpageId }) {
      id
      slug
      title
      content
      date
      author {
        node {
          name
        }
      }
      cPTTXBlogs {
        nodes {
          name
          slug            
        }
      }
      seo {
        focuskw
        title
        canonical
        opengraphUrl
        metaDesc
      }
      featuredImage {
        node {
          altText
          description
          title
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000, quality: 50) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`