import React, { useEffect, useState } from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import Image from 'gatsby-image'
import parse from 'html-react-parser'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faChevronLeft,
  faChevronRight,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons'

const Page = ({ pageContext, page, career, careerWp }) => {
  // console.log(career)
  return (
    <>
      <div className={``}>

        <div className={`blogListHeading column column--left`}>
          <h1 className={page.title.length > 8 ? 'heading--long' : ''}>
            {page.title}
          </h1>
          <div className={`wpContent`} dangerouslySetInnerHTML={{ __html: page.content }}></div>
        </div>

      </div>

      <div className="career faq">

        <h2 style={{textTransform:'uppercase',alignSelf:'flex-start'}}>{careerWp.acfblogpagelist.subheaderBlogPageList}</h2>

        <section style={{width:'100%'}}>
        {career.map(item => (
          <>
          <div className="card" key={item.id}>
            <AniLink 
              swipe
              duration={1.6}
              direction="left"
              entryOffset={400}
              to={`/${item.slug}`}
              data-hover={item.title}
              className={`cta2 cta2object`}
            >
              {item.featuredImage && <Image 
                fluid={item.featuredImage.node.localFile.childImageSharp.fluid} 

                // style={{maxWidth:'610px'}} 
                // style={{maxWidth:'100%'}} 

                alt={item.featuredImage.node.altText}
                title={item.featuredImage.node.title}
                description={item.featuredImage.node.description}
              />}
              <span className={`absoluteTitle`}>
                <h3>
                  {item.title}
                </h3>
              </span>
              <span style={{paddingRight:'20px',fontSize:'13px',color:'#fff'}}>{item.author.node.name}</span>
              <span style={{fontSize:'13px',color:'#fff'}}>{item.date.slice(0, item.date.indexOf('T'))}</span>
              <div>{item.cPTTXBlogs.nodes.map(cat => (<span style={{fontSize:'11px',color:'#fff', paddingRight:'12px'}}>{cat.name}</span>))}</div>
            </AniLink>
          </div>
          <div style={{margin:'0 auto 68px auto',width:'100%',borderTop:'1px solid white'}}></div>
          </>
        ))}
        </section>

      </div>
    </>
  )
}

const Subpage = ({ page, subpage, pageContext, career, careerWp }) => {
  // console.log(subpage)
  const featuredImage = {
    fluid: subpage.featuredImage?.node?.localFile?.childImageSharp?.fluid,
    alt: subpage.featuredImage?.node?.altText || ``,
  }
  let currentSubpageIndex;
  const getSubpageUri = ({ nextPage }) => {
    currentSubpageIndex = career.findIndex(
      x => x.slug === subpage.slug,
    )
    if (
      currentSubpageIndex < 0 ||
      (nextPage && currentSubpageIndex >= career.length - 1) ||
      (!nextPage && currentSubpageIndex <= 0)
    )
      return false
    return `/${career[currentSubpageIndex + (nextPage ? 1 : -1)].slug}`
  }
  const nextPage = getSubpageUri({ nextPage: true })
  const prevPage = getSubpageUri({ nextPage: false })
  useEffect(() => {
    const timer = setTimeout(() => {
      window.scroll({
        top: 0,
        left: 0,
      })
    }, 50);
    return () => clearTimeout(timer);
  }, [])
  return (
    <>
      <div className={`careerSingle faq`}>
        <div className={`careerSubnavWrapEXT`}>
        <div className={`careerSubnavWrap`}>
          {prevPage ?
            <AniLink 
              swipe
              duration={1.6}
              direction="right"
              entryOffset={400}
              className={`careerSubnav careerSubnavPrev cta2`} 
              to={prevPage}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              {careerWp.acfblogpagelist.paginationPrevBlogPageList}
            </AniLink> : ''}
          {nextPage ?
            <AniLink 
              swipe
              duration={1.6}
              direction="left"
              entryOffset={400}
              className={`careerSubnav careerSubnavNext cta2`} 
              to={nextPage}
            >
              {careerWp.acfblogpagelist.paginationNextBlogPageList}
              <FontAwesomeIcon icon={faChevronRight} />
            </AniLink> : ''}
          </div>
            <AniLink 
              swipe
              duration={1.6}
              direction="left"
              entryOffset={400}
              className={`careerSubnav careerSubnavBack cta2`} 
              to={`/${pageContext.pageSlug}`}
            >
              {careerWp.acfblogpagelist.gobackBlogPageList}
              <FontAwesomeIcon icon={faChevronUp} />
            </AniLink>
        </div>
        <h1 className={subpage.title.length > 6 ? 'heading--long' : ''}>
          {subpage.title}
        </h1>
        <div className={`credits`}>
          <span style={{paddingRight:'20px',fontSize:'13px'}}>{subpage.author.node.name}</span>
          <span style={{fontSize:'13px'}}>{subpage.date.slice(0, subpage.date.indexOf('T'))}</span>
          <div>{subpage.cPTTXBlogs.nodes.map(cat => (<span style={{fontSize:'11px',color:'#fff', paddingRight:'12px'}}>{cat.name}</span>))}</div>
        </div>
        {featuredImage?.fluid && (
          <Image
            fluid={featuredImage.fluid}
            alt={featuredImage.alt}
            style={{ marginBottom: 50, maxWidth:'610px' }}
          />
        )}

        <article>
          {parse(subpage.content || '')}
        </article>

        <div className={`careerSubnavWrapEXT`}>
        <div className={`careerSubnavWrap`}>
          {prevPage ?
            <AniLink 
              swipe
              duration={1.6}
              direction="right"
              entryOffset={400}
              className={`careerSubnav careerSubnavPrev cta2`} 
              to={prevPage}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              {careerWp.acfblogpagelist.paginationPrevBlogPageList}
            </AniLink> : ''}
          {nextPage ?
            <AniLink 
              swipe
              duration={1.6}
              direction="left"
              entryOffset={400}
              className={`careerSubnav careerSubnavNext cta2`} 
              to={nextPage}
            >
              {careerWp.acfblogpagelist.paginationNextBlogPageList}
              <FontAwesomeIcon icon={faChevronRight} />
            </AniLink> : ''}
            </div>

            <AniLink 
              swipe
              duration={1.6}
              direction="left"
              entryOffset={400}
              className={`careerSubnav careerSubnavBack cta2`} 
              to={`/${pageContext.pageSlug}`}
            >
              {careerWp.acfblogpagelist.gobackBlogPageList}
              <FontAwesomeIcon icon={faChevronUp} />
            </AniLink>
        </div>
      </div>

    </>
  )
}


const BlogList = (props) => {
  const pagePosts = useStaticQuery(graphql`
    query BlogPosts {
      career: wpCptBlogPage(AcfGatsbyId: {pageid: {eq: "blog-list"}}) {      
        acfblogpagelist {
          subheaderBlogPageList
          paginationPrevBlogPageList
          paginationNextBlogPageList
          gobackBlogPageList
        }
      }
    }
  `)
  const childProps = {
    ...props,
    career: props.page.wpChildren.nodes,
    careerWp: pagePosts.career
  }
  return (!props.subpage) ? Page(childProps) : Subpage(childProps)
}
export default BlogList